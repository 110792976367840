'use strict';

var $ = window.$;

$(document).ready(function () {

  $('#requestForm').on('submit', function (e) {

    e.preventDefault();

    $('[data-remodal-id="modal-success"]').remodal().open();


  });

  window.VMasker(document.querySelectorAll('input[name=phone]')).maskPattern('+9 (999) 999-9999');

  $('input[name=phone]').on('click', function () {
    if ($(this).val() === '') {
      $(this).val('+7');
    }
  });

  $('input[name=phone]').on('keyup', function () {
    if ($(this).val() === '') {
      $(this).val('+7');
    }
  });



  $('input[name=restoreMethod]').on('click', function () {
    var method = $(this).val();
    var checkedInput = $('input[name=' + method + ']').parent('.form__item');

    checkedInput.show();
    checkedInput.siblings('.js_checkedMethod').hide();
  });















});